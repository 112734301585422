
import * as request from 'request'
export async function writeHTTP(options, fullResponse){
    return new Promise((resolve, reject) => {
      request(options, function (error, response, body) {
        if (error) reject(error);
        console.log(response.statusCode)
        if(fullResponse){
          resolve(response)
        }else{
          resolve(body)
        }
      });
    });
  }