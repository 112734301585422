// action - account reducer
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const ACCOUNT_INITIALIZE = 'ACCOUNT_INITIALIZE';

// action - customization reducer
export const SET_MENU = '@customization/SET_MENU';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const SET_FONT_FAMILY = '@customization/SET_FONT_FAMILY';
export const SET_BORDER_RADIUS = '@customization/SET_BORDER_RADIUS';

// services reducer
export const LOAD_SERVICES = '@services/LOAD_SERVICES'

export const CUSTOMER_INITIALIZE = '@customer/CUSTOMER_INITIALIZE'
