import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ACCOUNT_INITIALIZE, CUSTOMER_INITIALIZE } from './store/actions';

import { ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline, StyledEngineProvider } from '@material-ui/core';

// routing
import Routes from './routes';

// defaultTheme
import theme from './themes';

// project imports
import NavigationScroll from './layout/NavigationScroll';

import { Auth0Provider } from '@auth0/auth0-react';
import { Redirect } from "react-router-dom";
import { useAuth0 } from '@auth0/auth0-react';


//-----------------------|| APP ||-----------------------//

const App = () => {
    const customization = useSelector((state) => state.customization);
    const dispatcher = useDispatch();
    const auth0 = useAuth0();
    

    const onRedirectCallback = (appState) => {
        // console.log(appState) 
        Redirect(
            appState && appState.returnTo
            ? appState.returnTo
            : window.location.origin+"/dashboard"
        );
    };

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme(customization)}>
            <Auth0Provider
                domain="nemcrunchers.auth0.com"
                clientId="2Jr69GYdVjd0hw3dbdqcxx21OsaqNf34"
                redirectUri={window.location.origin+"/callback"}
                cacheLocation="localstorage" >
                <CssBaseline />
                <NavigationScroll>
                    <Routes />
                </NavigationScroll> 
            </Auth0Provider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
