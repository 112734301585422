/* eslint-disable no-undef */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomer, updatePayment } from '../../services/customerService'
import 'react-credit-cards/es/styles-compiled.css';
// import {Form} from 'react'

import Cards from 'react-credit-cards';

// material-ui
import { useTheme } from '@material-ui/styles';
import {
    LinearProgress,
    Alert,
    Drawer,
    Fab,
    FormControl,
    Grid,
    IconButton,
    Input,
    InputLabel,
    FormHelperText,
    MenuItem,
    Slider,
    Select,
    Box,
    Button,
    TextField,
    Tooltip,
    Typography
} from '@material-ui/core';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import SubCard from '../../ui-component/cards/SubCard';
import { gridSpacing } from '../../store/constant';

// assets
import { IconPlus } from '@tabler/icons';

// concat 'px'
function valueText(value) {
    return `${value}px`;
}

const PaymentDetails = React.forwardRef((props, ref) => {
    //create your forceUpdate hook
    const theme = useTheme();
    const dispatcher = useDispatch();
    const {customer, isInitialized} = useSelector((state) => state.customer);

    const account = useSelector((state) => state.account);
    // const customization = useSelector((state) => state.customization);

    // drawer on/off
    const {open, onClose} = props
    const handleToggle = () => {
        setOpen(!open);
    };

    // state - isPending
    const [isPending, setIsPending] = React.useState(false);
    // state - isEdit
    const [isEdit, setIsEdit] = React.useState(!(customer.card) && isInitialized);
    // state - name
    const [name, setName] = React.useState("");
    const handleNameChange = (newValue) => {
        setName(newValue);
    };
    // state - number
    const [number, setNumber] = React.useState(customer.card?.cardNumber??"");
    const handleNumberChange = (newValue) => {
        // console.log(event.target.value)
        setNumber(newValue);
    };
    // state - cvc
    const [cvv, setCVV] = React.useState("");
    const handleCVVChange = (newValue) => {
        setCVV(newValue.substring(0,4));
    };
    // state - expiryMonth
    const [expiryMonth, setExpiryMonth] = React.useState("");
    const handleExpiryMonthChange = (newValue) => {
        let val = ('0'+newValue)
        setExpiryMonth(val.substring(val.length-2,val.length));
    };
    // state - expiryYear
    const [expiryYear, setExpiryYear] = React.useState("");
    const handleExpiryYearChange = (newValue) => {
        setExpiryYear(newValue.substring(newValue.length-2,newValue.length));
    };
    // state - Address
    const [customerAddress, setCustomerAddress] = React.useState("");
    const handleCustomerAddressChange = (newValue) => {
        setCustomerAddress(newValue);
    };
    // state - Name
    const [customerName, setCustomerName] = React.useState(customer.contactName);
    const handleCustomerNameChange = (newValue) => {
        setCustomerName(newValue);
    };
    // state - Postal Code
    const [postalCode, setPostalCode] = React.useState("");
    const handlePostalCodeChange = (newValue) => {
        setPostalCode(newValue);
    };
    const [responseMessage, setResponseMessage] = React.useState("");
    const [responseType, setResponseType] = React.useState("error");

    const clearForm = ()=>{

        setName("");
        setCVV("");
        setExpiryMonth("")
        setExpiryYear("")
        setCustomerAddress("")
        setPostalCode("")
        setIsEdit(false);
        setResponseMessage("");
    }

    // const handleDeploy = (event) => {
    //     deployService(account.token,{region: region, product: product}).then((success) => {
    //         if(success){
    //             setDeployMessage("Deployment started! It may take up to 30 minutes for your deployment to be ready")
    //             handleToggle();
    //         }
    //         setDeploying(false);
    //     })
    //     setDeploying(true);
    // }
    React.useEffect(() => {
        
      }, []);

      function handleSubmit(event) {
        setIsPending(true)
        helcimProcess().then(async (res) => {
            
            console.log(res)
            var doc = new DOMParser().parseFromString(res, "text/xml");
            var response = doc.getElementById('response')
            console.log(response)
            console.log(response.getAttribute('value'))
            if(response.getAttribute('value') === '0'){
                setResponseMessage(doc.getElementById('responseMessage').getAttribute('value'));
                setResponseType('error');
                setIsPending(false)
            }else{
                var cardNumber = doc.getElementById("cardNumber").getAttribute('value')
                console.log(cardNumber)
                var cardToken = doc.getElementById("cardToken").getAttribute('value')
                await updatePayment(account.token, {contactName: customerName, defaultCard: cardToken})
                await getCustomer(account.token, dispatcher)
                await setIsPending(false)
                helcimProcess()
                setNumber(cardNumber);
                clearForm()
                setResponseMessage('Card Approved')
                setResponseType('success');
                setTimeout(() => {
                    setResponseMessage('')
                }, 1500);
            }
        }, (rej) => {
            setResponseMessage(rej)
            setResponseType('error')
            setIsPending(false)
        })
        //event.preventDefault();
      }
    return (
        <React.Fragment>
            {/* toggle button */}
            <Drawer
                anchor="right"
                onClose={(event) => {clearForm();onClose(event)}}
                open={open}
                PaperProps={{
                    sx: {
                        width: 380
                    }
                }}
            >
                {isPending && <LinearProgress />}
                {!isPending && responseMessage && <Alert sx={{
                        m: 0,
                        position: 'fixed',
                        width: "100vw",
                        zIndex: (theme) => theme.zIndex.snackbar
                    }} severity={responseType}>{responseMessage}
                </Alert>}
                <PerfectScrollbar component="div">
                {customer && <form name="" action="" method="POST" id='helcimForm'>

                        <Grid container spacing={gridSpacing} sx={{ p: 3 }}>

                        <Grid item xs={12}>
                            <Cards
                                cvc={cvv}
                                expiry={expiryMonth+expiryYear}
                                focused={"true"}
                                name={name}
                                number={number}
                                placeholders={{name: ''}}
                                />
                        </Grid>
                        {isPending && <Grid item xs={12}>
                            <center>
                                <p>Verifying card...</p>
                            </center>
                        </Grid>}
                        
                            {/* <!--RESULTS--> */}
                            <div style={{display:"none"}} id="helcimResults"></div>

                            {/* <!--SETTINGS--> */}
                            <input type="hidden" id="token" value="edef6f2cfcec335c169b64"></input>
                            <input type="hidden" id="language" value="en"></input>
                            <input type="hidden" id="g-recaptcha-response" value=""></input>

                            {/* <!--CARD-INFORMATION--> */}
                        {isEdit && !isPending && <Grid item xs={12}>
                            <FormControl style={{width: '100%'}}>
                                <InputLabel htmlFor="cardHolderName">Card holder name</InputLabel>
                                <Input disabled={isPending}type="text" id="cardHolderName" value={name} onChange={(event)=> handleNameChange(event.target.value)}/>
                                {/* <FormHelperText id="my-helper-text">We do not store your card</FormHelperText> */}
                            </FormControl>
                        </Grid>}
                        {isEdit && !isPending && <Grid item xs={12}>
                            <FormControl style={{width: '100%'}}>
                                <InputLabel htmlFor="cardNumber">Card Number</InputLabel>
                                <Input disabled={isPending}type="text" id="cardNumber" value={number} onChange={(event)=> handleNumberChange(event.target.value)}/>
                                {/* <FormHelperText id="my-helper-text">We do not store your card</FormHelperText> */}
                            </FormControl>
                        </Grid>}
                        {isEdit && !isPending && <Grid item xs={3}>
                            <FormControl style={{width: '100%'}}>
                                <InputLabel htmlFor="cardExpiryMonth">Month</InputLabel>
                                <Input disabled={isPending}type="number" maxLength={2}  id="cardExpiryMonth" onChange={(event)=> handleExpiryMonthChange(event.target.value)} value={expiryMonth}/>
                                {/* <FormHelperText id="my-helper-text">We do not store your card</FormHelperText> */}
                            </FormControl>
                        </Grid>}
                        {isEdit && !isPending && <Grid item xs={3}>
                            <FormControl style={{width: '100%'}}>
                                <InputLabel htmlFor="cardHolderName">Year</InputLabel>
                                <Input disabled={isPending}onChange={(event)=> handleExpiryYearChange(event.target.value)} maxLength={2}  type="number" id="cardExpiryYear" value={expiryYear}/>
                                {/* <FormHelperText id="my-helper-text">We do not store your card</FormHelperText> */}
                            </FormControl>
                        </Grid>}
                        {isEdit && !isPending && <Grid item xs={6}>
                            <FormControl style={{width: '100%'}}>
                                <InputLabel htmlFor="cardCVV">CVV</InputLabel>
                                <Input disabled={isPending}type="text" maxLength={4} id="cardCVV" value={cvv}  onChange={(event)=> handleCVVChange(event.target.value)}/>
                                {/* <FormHelperText id="my-helper-text">We do not store your card</FormHelperText> */}
                            </FormControl>
                        </Grid>}
                        {isEdit && !isPending && <Grid item xs={12}>
                            <FormControl style={{width: '100%'}}>
                                <InputLabel htmlFor="customerName">Customer/Business name</InputLabel>
                                <Input disabled={isPending}type="text" id="customerName" value={customerName} onChange={(event)=> handleCustomerNameChange(event.target.value)}/>
                                {/* <FormHelperText id="my-helper-text">We do not store your card</FormHelperText> */}
                            </FormControl>
                        </Grid>}
                        {isEdit && !isPending && <Grid item xs={8}>
                            <FormControl style={{width: '100%'}}>
                                <InputLabel htmlFor="cardHolderAddress">Customer address</InputLabel>
                                <Input disabled={isPending}type="text" id="cardHolderAddress" value={customerAddress} onChange={(event)=> handleCustomerAddressChange(event.target.value)}/>
                                {/* <FormHelperText id="my-helper-text">We do not store your card</FormHelperText> */}
                            </FormControl>
                        </Grid>}
                        {isEdit && !isPending && <Grid item xs={4} >
                            <FormControl style={{width: '100%'}}>
                                <InputLabel htmlFor="cardHolderPostalCode">Postal Code</InputLabel>
                                <Input disabled={isPending}type="text" id="cardHolderPostalCode" value={postalCode} onChange={(event)=> handlePostalCodeChange(event.target.value)}/>
                                {/* <FormHelperText id="my-helper-text">We do not store your card</FormHelperText> */}
                            </FormControl>
                        </Grid>}
                            
                            {/* <!--OPTIONAL-CUSTOMER--> */}
                            <input type="hidden" id="customerCode" value={customer.customerCode}></input><br/>
                            <input type='hidden' id='test' value='1'></input>
                            {/* <input type='hidden'  value=''></input> */}
                            {/* <!--BUTTON--> */} 

                        {isEdit && !isPending && <Grid item xs={12} alignItems="flex-end">
                            <Box
                                m={1}
                                //margin
                                display="flex"
                                justifyContent="flex-end"
                                alignItems="flex-end"
                                // sx={boxDefault}
                                >
                                {/* // eslint-disable-next-line no-script-url 
                                // eslint-disable-next-line no-undef*/}
                                <Button onClick={handleSubmit} variant="contained" color="primary">
                                    Update Payment Details
                                </Button>
                            </Box>
                        </Grid>}
                        {!isEdit && <Grid item xs={12} alignItems="flex-end">
                            <Box
                                m={1}
                                //margin
                                display="flex"
                                justifyContent="flex-end"
                                alignItems="flex-end"
                                // sx={boxDefault}
                                >
                                {/* // eslint-disable-next-line no-script-url 
                                // eslint-disable-next-line no-undef*/}
                                <Button onClick={()=> {setIsEdit(true)}} variant="contained" color="primary">
                                    Edit Payment Details
                                </Button>
                            </Box>
                        </Grid>}

                    </Grid>
                        </form>}
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12}>
                            <center>
                                <p>We use <a href="https://www.helcim.com/security/" target="blank">Helcim</a> to store and process payments</p>
                            </center>
                        </Grid>
                    </Grid>
                </PerfectScrollbar> 
            </Drawer>
        </React.Fragment>
    );
});

export default PaymentDetails;
