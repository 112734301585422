import {writeHTTP} from './../common'
import {CUSTOMER_INITIALIZE } from '../../store/actions';


export async function updatePayment(token, customer){
    let url = window.location.protocol+"//"+window.location.host+'/.netlify/functions/customers';
    console.log(url)
        const options = {
          method: 'PATCH',
          url: url,
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
          },
          body: JSON.stringify({
            contactName: customer.contactName,
            defaultCard: customer.defaultCard
          })
        };
        let res = await writeHTTP(options, true)
        if(res.statusCode < 400){
            return ;
        }else if(res.statusCode === 401){
          throw new Error('Unauthorized call')
        }
        return;
  }

  export async function getCustomer(token, dispatcher){
    let url = window.location.protocol+"//"+window.location.host+'/.netlify/functions/customers';
    console.log(url)
        const options = {
          method: 'GET',
          url: url,
          headers: {
            Authorization: `Bearer ${token}`
          }
        };
        let res = await writeHTTP(options, true)
        if(res.statusCode < 400){
            const customer = JSON.parse(res.body)
            console.log("customer",customer)
            if(customer && dispatcher){
                dispatcher({
                    type: CUSTOMER_INITIALIZE,
                    payload: {
                        customer: customer
                    }
                })
            }
        }else if(res.statusCode === 401){
          throw new Error('Unauthorized call')
        }
        
        return;
  }