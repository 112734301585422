// services - state management
import { LOAD_SERVICES } from './actions';

export const initialState = {
    list:[],
    isInitialized: false
};

//-----------------------|| ACCOUNT REDUCER ||-----------------------//

const servicesReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_SERVICES: {
            const { list } = action.payload;
            return {
                ...state,
                list,
                isInitialized:true
            };
        }
        default: {
            return { ...state };
        }
    }
};

export default servicesReducer;
