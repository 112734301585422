import {writeHTTP} from './../common'


export async function getServices(token){
  let url = window.location.protocol+"//"+window.location.host+'/.netlify/functions/services';
  console.log(url)
      const options = {
        method: 'GET',
        url: url,
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
      let res = await writeHTTP(options, true)
      if(res.statusCode < 400){
        return JSON.parse(res.body)
      }else if(res.statusCode === 401){
        throw new Error('Unauthorized call')
      }
      return JSON.parse(res.body)
      
   
}

export async function toggleState(token, params){
  let url = window.location.protocol+"//"+window.location.host+'/.netlify/functions/services';
  console.log(url)
      const options = {
        method: 'PATCH',
        url: url,
        qs: {serviceId: params.serviceId, operation: params.operation},
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
      let res = await writeHTTP(options, true)
      if(res.statusCode < 400){
        return ""
      }else if(res.statusCode === 401){
        throw new Error('Unauthorized call')
      }
      return res.statusCode < 400
      
   
}
export async function deployService(token, deployParams){
  let url = window.location.protocol+"//"+window.location.host+'/.netlify/functions/services';
  console.log(url)
      const options = {
        method: 'POST',
        url: url,
        qs: {region: deployParams.region},
        headers: {
          Authorization: `Bearer ${token}`
        },
      };
      let res = await writeHTTP(options, true)
      if(res.statusCode < 400){
        return JSON.parse(res.body)
      }else if(res.statusCode === 401){
        throw new Error('Unauthorized call')
      }
      return JSON.parse(res.statusCode < 400)
      
   
}
