import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { ACCOUNT_INITIALIZE } from './../../store/actions';


import { useAuth0 } from '@auth0/auth0-react';

//-----------------------|| AUTH GUARD ||-----------------------//

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }) => {
    const history = useHistory();
    const {
        isAuthenticated,
        isLoading,
        loginWithRedirect,
        user,
        getIdTokenClaims
      } = useAuth0();
      const dispatcher = useDispatch();
      console.log(isAuthenticated, user)


    if (!isAuthenticated && !isLoading && window.location.pathname !== "/callback") {
        loginWithRedirect();
    }
    if(isAuthenticated && !isLoading && window.location.pathname === "/callback"){
        
        if(user){
            console.log(user)
            getIdTokenClaims().then(claims => {
                console.log(claims.__raw)

                dispatcher({
                    type: ACCOUNT_INITIALIZE,
                    payload: { isLoggedIn: true, user: user, token: claims.__raw}
                });
            })
        }
        history.push("/dashboard");
    }

    return children;
};



AuthGuard.propTypes = {
    children: PropTypes.node
};

export default AuthGuard;
