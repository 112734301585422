// action - state management
import { CUSTOMER_INITIALIZE } from './actions';

export const initialState = {
    customer: {
        card: null,
        contactName: '',
        email: '',
        customerCode: ''
    },
    isInitialized: false
};

//-----------------------|| CUSTOMER REDUCER ||-----------------------//

const customerReducer = (state = initialState, action) => {
    switch (action.type) {
        case CUSTOMER_INITIALIZE: {
            console.log('CUSTOMER_INITIALIZE')
            const { customer } = action.payload;
            return {
                ...state,
                customer:customer,
                isInitialized: true,
                
            };
        }
        default: {
            return { ...state };
        }
    }
};

export default customerReducer;
