import React from 'react';
import { useSelector } from 'react-redux';

// material-ui
import { makeStyles } from '@material-ui/styles';
import { Button, Card, CardContent, Grid, Link, Stack, Typography } from '@material-ui/core';

// project imports
import AnimateButton from './../../../../ui-component/extended/AnimateButton';

// style constant
const useStyles = makeStyles((theme) => ({
    card: {
        background: theme.palette.primary.light,
        marginTop: '16px',
        marginBottom: '16px',
        overflow: 'hidden',
        position: 'relative',
        '&:after': {
            content: '""',
            position: 'absolute',
            width: '200px',
            height: '200px',
            border: '19px solid ',
            borderColor: theme.palette.primary.main,
            borderRadius: '50%',
            top: '65px',
            right: '-150px'
        },
        '&:before': {
            content: '""',
            position: 'absolute',
            width: '200px',
            height: '200px',
            border: '3px solid ',
            borderColor: theme.palette.primary.main,
            borderRadius: '50%',
            top: '145px',
            right: '-70px'
        }
    },
    cardWarning: {
        background: theme.palette.warning.light,
        marginTop: '16px',
        marginBottom: '16px',
        overflow: 'hidden',
        position: 'relative',
        '&:after': {
            content: '""',
            position: 'absolute',
            width: '200px',
            height: '200px',
            border: '19px solid ',
            borderColor: theme.palette.warning.main,
            borderRadius: '50%',
            top: '65px',
            right: '-150px'
        },
        '&:before': {
            content: '""',
            position: 'absolute',
            width: '200px',
            height: '200px',
            border: '3px solid ',
            borderColor: theme.palette.warning.main,
            borderRadius: '50%',
            top: '145px',
            right: '-70px'
        }
    },
    tagLine: {
        color: theme.palette.grey[900],
        opacity: 0.6
    },
    button: {
        color: theme.palette.grey[800],
        backgroundColor: theme.palette.primary.main,
        textTransform: 'capitalize',
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: theme.palette.primary.dark
        }
    },
    buttonWarning: {
        color: theme.palette.grey[800],
        backgroundColor: theme.palette.warning.main,
        textTransform: 'capitalize',
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: theme.palette.warning.dark
        }
    }
}));

//-----------------------|| PROFILE MENU - UPGRADE PLAN CARD ||-----------------------//

const UpgradePlanCard = (props) => {
    const {updatePlanAction} = props;
    const classes = useStyles();
    const {customer, isInitialized} = useSelector((state) => state.customer);

    return (
        <div>
            {isInitialized && <Card className={(customer.card)?classes.card:classes.cardWarning}>
                <CardContent>
                    <Grid container direction="column" spacing={2}>
                        <Grid item>
                            <Typography variant="h4">
                            {!(customer.card?.active) && "Payment method missing"}
                            {customer.card?.active && "Payment method added"}
                            </Typography>
                        </Grid>
                        <Grid item>
                            {!(customer.card?.active) && <Typography variant="subtitle2" className={classes.tagLine}>
                                Please add payment method before deploying any applications
                            </Typography>}
                        </Grid>
                        <Grid item>
                            <Stack direction="row">
                                <AnimateButton>
                                    <Button
                                        component={Link}
                                        onClick={updatePlanAction}
                                        variant="contained"
                                        className={(customer.card)?classes.button:classes.buttonWarning}
                                    >
                                        Update
                                    </Button>                                
                                </AnimateButton>
                            </Stack>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>}
        </div>
        
    );
};

export default UpgradePlanCard;
