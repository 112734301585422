import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deployService } from './../../services/servicesService'

// material-ui
import { useTheme } from '@material-ui/styles';
import {
    Snackbar,
    Alert,
    Drawer,
    Fab,
    FormControl,
    Grid,
    IconButton,
    MenuItem,
    Slider,
    Select,
    Box,
    Button,
    TextField,
    Tooltip,
    Typography
} from '@material-ui/core';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import SubCard from '../../ui-component/cards/SubCard';
import { gridSpacing } from '../../store/constant';

// assets
import { IconPlus } from '@tabler/icons';

// concat 'px'
function valueText(value) {
    return `${value}px`;
}


const Customization = (props) => {
    const theme = useTheme();
    // const dispatch = useDispatch();

    const account = useSelector((state) => state.account);
    // const customization = useSelector((state) => state.customization);

    // drawer on/off
    const [open, setOpen] = React.useState(false);
    const handleToggle = () => {
        setOpen(!open);
    };

    // state - instance count
    const [instanceCount, setInstanceCount] = React.useState(props.instanceCount??1);
    const handleInstanceCount = (event, newValue) => {
        setInstanceCount(newValue);
    };

    // useEffect(() => {
    //     dispatch({ type: SET_BORDER_RADIUS, borderRadius: borderRadius });
    // }, [dispatch, borderRadius]);

    // state - Deploying
    const [deploying, setDeploying] = React.useState(false);
    const [deployMessage, setDeployMessage] = React.useState("");
    // state - Product
    const [product, setProduct] = React.useState(props.product??'axelor');
    const handleProductChange = (event, newValue) => {
        setProduct(newValue.props.value);
    };
    // state - Region
    const [region, setRegion] = React.useState(props.region??'ohio');
    const handleRegionChange = (event, newValue) => {
        setRegion(newValue.props.value);
    };

    const handleDeploy = (event) => {
        deployService(account.token,{region: region, product: product}).then((success) => {
            if(success){
                setDeployMessage("Deployment started! It may take up to 30 minutes for your deployment to be ready")
                handleToggle();
            }
            setDeploying(false);
        })
        setDeploying(true);
    }

    return (
        <React.Fragment>
            {/* toggle button */}
            <Snackbar open={!!deployMessage} autoHideDuration={10000} onClose={() => {setDeployMessage("")}}>
                <Alert sx={{
                        top: 80,
                        left:0,
                        m: 0,
                        position: 'fixed',
                        width: "100vw",
                        zIndex: (theme) => theme.zIndex.speedDial
                    }} onClose={() => {setDeployMessage("")}} severity="success">{deployMessage}
                </Alert>
                </Snackbar>
            <Tooltip title="Add Service">
                <Fab
                    component="div"
                    onClick={handleToggle}
                    size="medium"
                    variant="string"
                    color="primary"
                    sx={{
                        bottom: 0,
                        m: 4,
                        position: 'fixed',
                        right: 20,
                        zIndex: (theme) => theme.zIndex.speedDial,
                        boxShadow: theme.shadows[8]
                    }}
                >
                        <IconButton color="inherit" size="large" disableRipple>
                            <IconPlus />
                        </IconButton>
                </Fab>
            </Tooltip>

            <Drawer
                anchor="right"
                onClose={handleToggle}
                open={open}
                PaperProps={{
                    sx: {
                        width: 280
                    }
                }}
            >
                <PerfectScrollbar component="div">
                    <Grid container spacing={gridSpacing} sx={{ p: 3 }}>
                        <Grid item xs={12}>
                            <SubCard title="Product">
                                <FormControl xs={12}>
                                    <TextField
                                        id="standard-select-product"
                                        select
                                        value={product}
                                        onChange={handleProductChange}
                                    >
                                    <MenuItem value={'axelor'}>Axelor</MenuItem>

                                    </TextField>                                    
                                </FormControl>
                            </SubCard>
                        </Grid>
                        <Grid item xs={12}>
                            <SubCard title="Region">
                                <FormControl xs={12}>
                                    <TextField
                                        id="standard-select-region"
                                        select
                                        value={region}
                                        onChange={handleRegionChange}
                                    >
                                    <MenuItem value={'frankfurt'}>🇩🇪 Frankfurt</MenuItem>
                                    <MenuItem value={'ohio'}>🇺🇸 Ohio</MenuItem>
                                    <MenuItem value={'oregon'}>🇺🇸 Oregon</MenuItem>
                                    <MenuItem value={'singapore'}>🇸🇬 Singapore</MenuItem>

                                    </TextField>                                    
                                </FormControl>
                            </SubCard>
                        </Grid>
                        <Grid item xs={12}>
                            {/* border radius */}
                            <SubCard title="Number of instances">
                                <Grid item xs={12} container spacing={2} alignItems="center" sx={{ mt: 2.5 }}>
                                    <Grid item>
                                        <Typography variant="h6" color="secondary">
                                            1
                                        </Typography>
                                    </Grid>
                                    <Grid item xs>
                                        <Slider
                                            size="small"
                                            disabled
                                            value={instanceCount}
                                            onChange={handleInstanceCount}
                                            getAriaValueText={valueText}
                                            valueLabelDisplay="on"
                                            aria-labelledby="discrete-slider-small-steps"
                                            marks
                                            step={1}
                                            min={1}
                                            max={12}
                                            color="secondary"
                                            sx={{
                                                '& .MuiSlider-valueLabel': {
                                                    color: 'secondary.light'
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="h6" color="secondary">
                                            12
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <p>*currently not supported</p>
                            </SubCard>
                        </Grid>

                        <Grid item xs={12} alignItems="flex-end">
                            <Box
                                m={1}
                                //margin
                                display="flex"
                                justifyContent="flex-end"
                                alignItems="flex-end"
                                // sx={boxDefault}
                                >
                                <Button onClick={handleDeploy} variant="contained" color="primary">
                                    Deploy
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </PerfectScrollbar> 
            </Drawer>
        </React.Fragment>
    );
};

export default Customization;
